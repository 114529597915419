import { globalProperties } from '@/main'
import { computed, reactive, readonly, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import { firebaseApp } from '@/main.js'
import { getFirestore, collection, query, orderBy, getDocs, doc, setDoc, deleteDoc } from 'firebase/firestore'

const db = getFirestore(firebaseApp)

const state = reactive({
  _applications: [],
  selectedAppId: null
})

export default function () {
  const getAppById = id => state._applications.find(a => a.id === id)

  const selectedApp = computed(() => getAppById(state.selectedAppId))

  const setSelectedApp = id => {
    state.selectedAppId = id
  }

  const addApplication = async application => {
    try {
      const documentRef = doc(db, `APPS/${application.id}`)
      await setDoc(documentRef, application)
      fetchApplications(true)
    } catch (e) {
      console.error('Error writing document: ', e)
    }
  }

  const updateApplication = async application => {
    try {
      if (!application.id) return
      const documentRef = doc(db, `APPS/${application.id}`)
      await setDoc(documentRef, application)
      fetchApplications(true)
    } catch (e) {
      console.error('Error : ', e)
    }
  }

  const deleteApplication = async ({ id }) => {
    try {
      const documentRef = doc(db, `APPS/${id}`)
      await deleteDoc(documentRef)
      fetchApplications(true)
    } catch (e) {
      console.error('Error deleting document: ', e)
    }
  }

  const fetchApplications = async (force = false) => {
    try {
      if (force || !state._applications.length) {
        const appsCollectionRef = collection(db, 'APPS')
        const appsQuery = query(appsCollectionRef, orderBy('id'))
        const querySnap = await getDocs(appsQuery)

        state._applications = querySnap.docs.map(doc => doc.data())
        setSelectedApp(null)
      }
    } catch (e) {
      console.error('Error fetching applications: ', e)
    }
  }

  const applications = computed(() => {
    const route = useRoute()
    if (
      globalProperties.roles.global?.includes('super-admin') ||
      globalProperties.roles.global?.includes('admin') ||
      globalProperties.roles['perfboard']?.includes('all-apps')
    )
      return state._applications
    if (route && globalProperties.roles['perfboard']?.includes('responsible'))
      return state._applications.filter(app => app.responsibles?.find(r => r.email === globalProperties.user.mail)?.[route.name])

    return []
  })

  return {
    ...toRefs(readonly(state)),
    fetchApplications,
    selectedApp,
    setSelectedApp,
    addApplication,
    updateApplication,
    deleteApplication,
    applications,
    getAppById
  }
}
