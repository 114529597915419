import ky from 'ky'
import { globalProperties } from '@/main'

export const http = ky.create({prefixUrl: process.env.VUE_APP_BACKEND, timeout: 60000})

export const extendWithAuthorization = async http => {
  const token = await globalProperties.firebaseUser.getIdToken()
  const headers = {
    Authorization: `Bearer ${ token }`,
    application: process.env.VUE_APP_APP_NAME
  }

  return http.extend({
    headers
  })
}
