import { extendWithAuthorization, http } from '@/http'
import JOBS from '@/mock/jobs.json'
import { firebaseApp } from '@/main.js'
import { getFirestore, collection, query, where, getDocs, getDoc, setDoc, doc, deleteDoc } from 'firebase/firestore'
import { reactive, readonly, toRefs } from 'vue'

const db = getFirestore(firebaseApp)

const state = reactive({
  demands: {},
  authorizations: {},
  usersInfos: {}
})

export default function () {
  const getDemands = async application => {
    if (application && !state.demands[application]) {
      try {
        const demandsCollectionRef = collection(db, 'AUTHORIZATIONS/DATA/DEMANDS')
        const demandsQuery = query(demandsCollectionRef, where('app', '==', application))

        const querySnap = await getDocs(demandsQuery)
        const demands = querySnap.docs.map(doc => doc.data())

        await fetchUsersInfos(demands.map(d => d.email))

        state.demands[application] = demands.map(demand => ({ ...demand, ...state.usersInfos[demand.email] }))
      } catch (e) {
        console.error('Error fetching demands: ', e)
      }
    }
    return state.demands[application]
  }

  const deleteDemand = async (email, app) => {
    try {
      const documentRef = doc(db, `AUTHORIZATIONS/DATA/DEMANDS/${email}:${app}`)
      await deleteDoc(documentRef)

      state.demands[app] = state.demands[app].filter(d => !(d.email === email && d.app === app))

      console.log('Demand deleted successfully!')
    } catch (e) {
      console.error('Error deleting demand: ', e)
    }
  }

  const resetAuthorizations = () => {
    state.authorizations = {}
  }

  const fetchUsersInfos = async emails => {
    const filteredEmails = emails.filter(email => !state.usersInfos[email])

    filteredEmails.forEach(
      mail => (state.usersInfos[mail] = { dummy: true, mail, displayName: 'Chargement du nom...', uid: 'Chargement du matricule...' })
    )

    const extendedHttp = await extendWithAuthorization(http)
    const promises = []
    for (let i = 0; i < filteredEmails.length; i += 25) {
      promises.push(
        extendedHttp
          .get(`user?filter=mail&values=${filteredEmails.slice(i, i + 25).join()}`, {
            retry: {
              limit: 4,
              methods: ['get'],
              statusCodes: [500]
            }
          })
          .json()
      )
    }
    const infos = (await Promise.all(promises)).flat()
    infos.forEach(user => (state.usersInfos[user.mail] = user))
    filteredEmails.forEach(mail => {
      if (state.usersInfos[mail]?.dummy) delete state.usersInfos[mail]
    })

    return emails.map(e => state.usersInfos[e])
  }

  const fetchRolesUsers = async (application, role) => {
    try {
      if (application && !state.authorizations[application]?.[role]) {
        // Récupérer les utilisateurs pour le rôle spécifié
        const usersQuery = query(collection(db, 'AUTHORIZATIONS/DATA/USERS'), where(`roles.${application}`, 'array-contains', role))
        const usersSnap = await getDocs(usersQuery)
        const users = usersSnap.docs.map(doc => doc.id)

        // Récupérer les groupes pour le rôle spécifié
        const groupsQuery = query(collection(db, 'AUTHORIZATIONS/DATA/GROUPS'), where(`roles.${application}`, 'array-contains', role))
        const groupsSnap = await getDocs(groupsQuery)
        const groups = groupsSnap.docs.map(doc => `group:${doc.id}`)

        // Mettre à jour state.authorizations avec les utilisateurs et groupes récupérés
        fetchUsersInfos([...users, ...groups]) // Appel à fetchUsersInfos avec tous les utilisateurs et groupes
        if (!state.authorizations[application]) state.authorizations[application] = {}
        state.authorizations[application][role] = [...users, ...groups]
      }
    } catch (error) {
      console.error('Error fetching roles users:', error)
    }
  }

  const getRolesByDocRef = async docRef => {
    try {
      const snapshot = await getDoc(doc(db, docRef))
      if (snapshot.exists()) {
        return snapshot.data().roles || []
      } else {
        console.error('Document does not exist')
        return []
      }
    } catch (error) {
      console.error('Error fetching roles:', error)
      return []
    }
  }

  const getUsersByRole = (application, role) =>
    (state.authorizations[application]?.[role] ?? []).map(u => {
      const getJobLabel = ({ id, label }) => `${id}|${label}`
      if (u.startsWith('group:')) {
        const id = u.split(':')[1]
        const job = JOBS.find(job => job.id === id)
        return { displayName: job ? getJobLabel(job) : id, uid: u, mail: u }
      } else {
        return state.usersInfos[u] ?? { displayName: 'INCONNU DU LDAP', mail: u }
      }
    })

  const getUserRoles = async ({ mail, iirBusinessUnit, jobs }) => {
    let userRolesPromise,
      businessUnitsRolesPromise,
      everybodyRolesPromise,
      jobsRolesPromises = []

    if (mail.startsWith('group:')) {
      userRolesPromise = getRolesByDocRef(`AUTHORIZATIONS/DATA/GROUPS/${mail.split(':')[1]}`)
    } else {
      userRolesPromise = getRolesByDocRef(`AUTHORIZATIONS/DATA/USERS/${mail}`)
      businessUnitsRolesPromise = getRolesByDocRef(`AUTHORIZATIONS/DATA/GROUPS/${iirBusinessUnit}`)
      jobsRolesPromises = jobs?.map(job => getRolesByDocRef(`AUTHORIZATIONS/DATA/GROUPS/${job.id}`)) || []
      everybodyRolesPromise = getRolesByDocRef('AUTHORIZATIONS/DATA/GROUPS/EVERYBODY')
    }

    let [userRoles, buRoles, everybodyRoles, ...jobsRoles] = await Promise.all([
      userRolesPromise,
      businessUnitsRolesPromise,
      everybodyRolesPromise,
      ...jobsRolesPromises
    ])

    jobsRoles = jobsRoles.reduce((acc, value) => {
      for (const app of Object.keys(value)) {
        acc[app] = [...new Set((acc[app] || []).concat(value[app]))]
      }
      return acc
    }, {})

    return { userRoles, buRoles, everybodyRoles, jobsRoles }
  }

  const deleteUserRole = async (email, appId, role) => {
    try {
      let roles

      if (email.startsWith('group:')) {
        roles = await getRolesByDocRef(`AUTHORIZATIONS/DATA/GROUPS/${email.split(':')[1]}`)
      } else {
        roles = await getRolesByDocRef(`AUTHORIZATIONS/DATA/USERS/${email}`)
      }

      roles[appId] = roles[appId].filter(r => r !== role)
      state.authorizations[appId][role] = state.authorizations[appId][role].filter(mail => mail !== email)

      // Mettre à jour les rôles de l'utilisateur ou du groupe dans Firestore
      await setUser(email, { roles })

      console.log(`Role '${role}' deleted for '${email}' in '${appId}'.`)
    } catch (error) {
      console.error('Error deleting user role:', error)
    }
  }

  const addUserRole = async (email, appId, role) => {
    try {
      const roles = await getRolesByDocRef(`AUTHORIZATIONS/DATA/USERS/${email}`)
      roles[appId] ??= []
      if (roles[appId].includes(role)) return

      roles[appId].push(role)

      // Mettre à jour les informations des utilisateurs
      fetchUsersInfos([email])

      // Mettre à jour state.authorizations
      state.authorizations[appId][role] ??= []
      state.authorizations[appId][role].push(email)

      // Mettre à jour les rôles de l'utilisateur dans Firestore
      await setUser(email, { roles }, true)

      console.log(`Role '${role}' added for '${email}' in '${appId}'.`)
    } catch (error) {
      console.error('Error adding user role:', error)
    }
  }

  const deleteUser = async email => {
    try {
      if (email.startsWith('group:')) return

      // Supprimer l'utilisateur de Firestore
      const documentRef = doc(db, `AUTHORIZATIONS/DATA/USERS/${email}`)
      await deleteDoc(documentRef)

      // Mettre à jour state.authorizations
      Object.keys(state.authorizations).forEach(app => {
        Object.keys(state.authorizations[app]).forEach(role => {
          state.authorizations[app][role] = state.authorizations[app][role].filter(u => u !== email)
        })
      })

      console.log(`User '${email}' deleted successfully.`)
    } catch (error) {
      console.error('Error deleting user:', error)
    }
  }

  const setUser = async (email, { roles, displayName, type }, merge = false) => {
    try {
      if (!email) return

      // Filtrer les rôles vides
      const filteredRoles = Object.fromEntries(Object.entries(roles).filter(entry => entry[1].length))

      if (email.startsWith('group:')) {
        // Si c'est un groupe, mettre à jour les données dans la collection GROUPS
        const groupId = email.split(':')[1]
        const groupRef = doc(db, `AUTHORIZATIONS/DATA/GROUPS/${groupId}`)
        await setDoc(groupRef, { roles: filteredRoles, displayName, type }, { merge })
      } else {
        // Sinon, mettre à jour les données dans la collection USERS
        const userRef = doc(db, `AUTHORIZATIONS/DATA/USERS/${email}`)
        await setDoc(userRef, { roles: filteredRoles }, { merge })
      }

      console.log(`User or group '${email}' updated successfully.`)
    } catch (error) {
      console.error('Error setting user or group:', error)
    }
  }

  return {
    ...toRefs(readonly(state)),
    getDemands,
    deleteDemand,
    resetAuthorizations,
    fetchRolesUsers,
    fetchUsersInfos,
    getUsersByRole,
    getUserRoles,
    deleteUserRole,
    addUserRole,
    deleteUser,
    setUser
  }
}
