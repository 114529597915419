import './registerServiceWorker'
import InjectBaseComponents from '@/components/base'
import 'firebase/analytics'
import { createApp as createVueApp } from 'vue'
import App from './App.vue'
import Forbidden from './Forbidden.vue'
import router from './router'
import VTooltip from 'v-tooltip'

import { analytics } from '@/main.js'

export function createApp({ firebaseUser, user, roles }) {
  const app = createVueApp(App)
    .provide('analytics', analytics)
    .provide('user', firebaseUser)
    .provide('userInfos', user)
    .provide('roles', roles)
    .use(router)
    .use(VTooltip)

  InjectBaseComponents(app)
  app.mount('#app')
}

export function createForbiddenApp({ firebaseUser, userInfos, roles }) {
  const app = createVueApp(Forbidden).provide('user', firebaseUser).provide('userInfos', userInfos).provide('roles', roles)

  InjectBaseComponents(app)
  app.mount('#app')
}
