<template>
  <div class="container">
    <div id="forbidden">
      <div class="logo-container"> PerfBoard </div>
      <div class="title">Tu n’es pas autorisé à accéder à cette application</div>
      <div class="content">
        <div v-if="!demanded" class="left">
          <p>
            Besoin de demander l’accès? <br />
            Décris ci-dessous pourquoi tu souhaiterais avoir accès à l’application.
          </p>
          <textarea v-model="message" maxlength="500" placeholder="Entre ta description (500 caractères max)" cols="40" rows="7" />
          <div class="button-container">
            <button class="button" @click="sendDemand"> <v-svg h="1.5em" name="checkmark" /> Demander l’accès </button>
          </div>
        </div>
        <div v-else class="left">
          <p> Ta demande d'habilitation a été envoyée.</p>
        </div>
        <div class="right">
          <img src="@/assets/img/id-verification.png" class="illustration" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { computed, inject, ref } from 'vue'
  import ky from 'ky'

  export default {
    name: 'Forbidden',
    setup() {
      const message = ref('')
      const demand = computed(() => ({ application: process.env.VUE_APP_APP_NAME, message: message.value }))
      const demanded = ref(false)

      const user = inject('user')
      const http = ky.create({ prefixUrl: process.env.VUE_APP_BACKEND_SMART, timeout: 60000 })

      const getDemand = async () => {
        const token = await user.getIdToken()
        const d =
          (await http
            .extend({ headers: { Authorization: `Bearer ${token}`, project_id: process.env.VUE_APP_FIREBASE_PROJECT_ID } })
            .get(`authorization/demand`, { searchParams: { application: demand.value.application } })
            .json()) ?? {}
        demanded.value = !!d.timestamp
      }

      const sendDemand = async () => {
        const token = await user.getIdToken()
        await http
          .extend({ headers: { Authorization: `Bearer ${token}`, project_id: process.env.VUE_APP_FIREBASE_PROJECT_ID } })
          .post(`authorization/demand`, { json: demand.value })
          .json()
        demanded.value = true
      }

      getDemand()

      return {
        message,
        demanded,
        sendDemand
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;700&display=swap');

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #forbidden {
    width: 68.75rem;
    padding: 1rem;
    overflow-y: auto;
    max-height: stretch;
    .logo-container {
      display: flex;
      @media screen and (max-width: 1100px) {
        justify-content: center;
      }
      .logo {
        max-height: 2.75rem;
        max-width: 50vw;
      }
    }
    .title {
      font-family: Manrope;
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 2.0625rem;
      text-align: center;

      @media screen and (min-width: 1100px) {
        text-align: initial;
        line-height: 3.75rem;
        font-size: 2.75rem;
      }
    }
    .content {
      display: grid;
      @media screen and (min-width: 1100px) {
        grid-template-columns: 1fr 1fr;
      }
      gap: 2rem;
      .left {
        p {
          color: #21ade0;
          font-family: Manrope;
          font-weight: bold;
          text-align: center;
          font-size: 1rem;
          line-height: 1.375rem;
          @media screen and (min-width: 1100px) {
            text-align: initial;
          }
        }
        textarea {
          display: block;
          background-color: rgba(1, 12, 20, 0.05);
          border: none;
          resize: none;
          outline: none;
          padding: 1rem;
          width: 100%;
          font-family: Manrope;
          font-size: 0.875rem;
          border-radius: 0.5rem;
          line-height: 1.1875rem;
        }
        .button-container {
          display: flex;
          justify-content: center;
          @media screen and (min-width: 1100px) {
            justify-content: flex-end;
          }
          .button {
            display: flex;
            align-items: center;
            background-color: #21ade0;
            border: none;
            cursor: pointer;
            outline: none;
            border-radius: 0.5rem;
            padding: 0.5rem 0.75rem;
            font-family: Manrope;
            font-size: 0.875rem;
            color: white;
            margin-top: 1rem;
            .v-svg {
              margin-right: 0.6em;
            }
          }
        }
      }
      .right {
        .illustration {
          max-width: 100%;
          max-height: 25rem;
        }
      }
    }
  }
</style>
